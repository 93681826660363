import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../contexts/auth-context";
import {
  addOrEditProspectingGenerator,
  getProspectingGeneratorById,
} from "../../../scrap-generators/scrap-generator-service";
import {
  ProspectingStatusENUM,
  ScrapProspectingGeneratorsDTO,
} from "../../../scrap-generators/scrap-generator-prospecting-DTO";
import { useFieldArray, useForm } from "react-hook-form";
import { CurrencyInput } from "react-currency-mask";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { useAlert } from "../../../../contexts/alert-context";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../services/address-service";
import CountryInputAutocomplete from "../../../../components/CountryInputAutocomplete/CountryInputAutocomplete";
import { ScrapGeneratorDTO } from "../../../scrap-generators/scrap-generators-DTO";
import { CountryConfigDTO } from "../../../settings/components/Country-config/country-config-DTO";
import { CRMFunnelDTO } from "../../../settings/components/CRM/crm-dto";
import { getCRMSteps } from "../../../settings/components/CRM/crm-service";
import NotFound from "../../../../components/NotFound/NotFound";

const NewCrmCompanyForm: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const [countryConfigEntity, setCountryConfigEntity] =
    useState<CountryConfigDTO>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [prospectingGeneratorDetails, setProspectingGeneratorDetails] =
    useState<ScrapProspectingGeneratorsDTO>();
  const [currencyProspection, setCurrencyProspection] = useState<string>();
  const [countryError, setCountryError] = useState<boolean>(false);
  const [localeProspection, setLocaleProspection] = useState<string>();
  const [CRMSteps, setCRMSteps] = useState<CRMFunnelDTO[]>([]);
  const [selectedCountryDetails, setSelectedCountryDetails] =
    useState<CountryConfigDTO | null>(null);
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [isDisabled, setIsDisabled] = useState(false);
  const stepsRef = useRef<HTMLDivElement>(null);
  const { setAlert } = useAlert();
  const [, setNationalRegisterLabel] = useState<string>(
    "label-national-register"
  );
  const { headquarterParamId } = useParams<{ headquarterParamId: string }>();
  const [scrapGenerator, setScrapGenerator] =
    useState<ScrapGeneratorDTO | null>(null);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm<ScrapProspectingGeneratorsDTO>({
    defaultValues: {
      name: "",
      segment: "",
      revenues: "",
      fee: "",
      percentualFee: 0,
      sale: "",
      lead: "",
      contacts: [{ name: "", email: "", role: "" }],
      CRMSteps: CRMSteps,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });

  const fetchEntityCountry = async () => {
    if (id) {
      const data = await getAdrressByEntityId(id);
      const countryConfig = await getCountryConfigById(data.country);
      setCountryConfigEntity(countryConfig);
    }
  };

  useEffect(() => {
    fetchEntityCountry();
  }, [id]);

  const mergeCRMData = (
    crmSteps: CRMFunnelDTO[],
    prospectingDetails: ScrapProspectingGeneratorsDTO
  ) => {
    const funnelInfoMap: Record<string, CRMFunnelDTO["crmFunnelInfoDTO"]> =
      crmSteps.reduce((map: any, step: any) => {
        map[step.id] = step.crmFunnelInfoDTO;
        return map;
      }, {});

    const updatedSteps = crmSteps.map((step) => {
      const existingStep = prospectingDetails.CRMSteps.find(
        (prospectingStep) => prospectingStep.originId === step.id
      );
      return {
        originId: step.id,
        conclusionDate: existingStep?.conclusionDate || "",
        crmFunnelInfoDTO: funnelInfoMap[step.id],
      };
    });

    setProspectingGeneratorDetails({
      ...prospectingDetails,
      CRMSteps: updatedSteps,
    });
  };

  const handleCountryChange = (country: CountryConfigDTO) => {
    setCountryConfigEntity(country);
    setSelectedCountryDetails(country);
    setLocaleProspection(country.locale);
    setCurrencyProspection(country.currency);

    setNationalRegisterLabel(
      country.nationalRegister || "label-national-register"
    );
  };

  const onSubmit = async (
    prospectingDetails: ScrapProspectingGeneratorsDTO
  ) => {
    setIsDisabled(true);
    if (!selectedCountryDetails) {
      setCountryError(true);
    } else {
      setCountryError(false);
      try {
        const updatedDetails = {
          ...prospectingDetails,
          currency: countryConfigEntity?.currency || "",
          locale: countryConfigEntity?.locale || "",
        };

        await addOrEditProspectingGenerator(updatedDetails, id);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        navigate(`/scrap-generators`);
      } catch (error) {
        console.error("Error during submission:", error);
        setAlert({ text: t("alert-error-registered"), type: "error" });
      }
    }

    setIsDisabled(false);
  };

  const fetchDataCRMDetails = async () => {
    try {
      const [crmSteps, prospectingData] = await Promise.all([
        getCRMSteps(),
        id ? getProspectingGeneratorById(id) : null,
      ]);

      if (crmSteps) {
        setCRMSteps(crmSteps);
      }

      if (prospectingData) {
        setProspectingGeneratorDetails(prospectingData);
        setCurrencyProspection(prospectingData.currency);
        setLocaleProspection(prospectingData.locale);

        reset({
          name: prospectingData.name,
          segment: prospectingData.segment,
          revenues: prospectingData.revenues,
          fee: prospectingData.fee,
          sale: prospectingData.sale,
          percentualFee: prospectingData.percentualFee,
          lead: prospectingData.lead,
          contacts: prospectingData.contacts || [
            { name: "", email: "", role: "" },
          ],
          CRMSteps: prospectingData.CRMSteps,
        });
      }

      if (crmSteps && prospectingData) {
        mergeCRMData(crmSteps, prospectingData);
      }
    } catch (error) {
      console.error("Erro ao buscar dados iniciais:", error);
    }
  };

  useEffect(() => {
    fetchDataCRMDetails();
  }, [id]);

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        <div className="flex-row justify-between">
          <h3 style={{ margin: "0.5rem 0rem 0.5rem 0rem" }}>
            {t("label-business-information")}
          </h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-column margin-topbottom-1">
            <CountryInputAutocomplete
              label={`${t("label-country")}*`}
              placeholder={t("placeholder.country")}
              register={register}
              errors={errors}
              onChange={handleCountryChange}
              initialValue={scrapGenerator?.addressInfo?.countryName}
              isDisabled={!!scrapGenerator && !headquarterParamId}
            />
            {!selectedCountryDetails && countryError && (
              <span
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "4px",
                  display: "block",
                }}
              >
                {t("error-input-required")}
              </span>
            )}
          </div>

          <div className="form-fields">
            <div className="flex-column margin-topbottom-1">
              <label htmlFor="company-name">{t("label-company")}</label>
              <input
                placeholder={t("label-name")}
                type="text"
                id="company-name"
                {...register("name", {
                  required: true,
                })}
              />
              {errors.name && <span>{t("error-input-required")}</span>}
            </div>

            <div className="flex-column margin-topbottom-1">
              <label htmlFor="segment">{t("label-segment")}</label>
              <input
                placeholder={t("placeholder.segment")}
                type="text"
                id="segment"
                {...register("segment", {
                  required: true,
                })}
              />
              {errors.segment && <span>{t("error-input-required")}</span>}
            </div>

            <div className="double-fields">
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="revenues">{t("label-revenue")}</label>

                <CurrencyInput
                  currency={currencyProspection}
                  locale={localeProspection}
                  defaultValue={prospectingGeneratorDetails?.revenues || ""}
                  onChangeValue={(event, originalValue, maskedValue) => {
                    setValue("revenues", maskedValue.toString());
                    setValue("originRevenues", Number(originalValue));
                  }}
                  InputElement={
                    <input
                      placeholder={t("label-revenue")}
                      type="text"
                      id="targetPrice"
                      {...register("revenues", {
                        required: false,
                      })}
                    />
                  }
                />
                {errors.revenues && <span>{t("error-input-required")}</span>}
              </div>
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="scrap-sale">
                  {t("label-saleDisposal")} / {t("year")}
                </label>

                <CurrencyInput
                  currency={currencyProspection}
                  locale={localeProspection}
                  defaultValue={prospectingGeneratorDetails?.sale || ""}
                  onChangeValue={(event, originalValue, maskedValue) => {
                    setValue("sale", maskedValue.toString());
                    setValue("originSale", Number(originalValue));
                  }}
                  InputElement={
                    <input
                      placeholder={`${t(
                        "label-sales"
                      )} (${currencyProspection})`}
                      type="text"
                      id="scrap-sale"
                      {...register("sale", {
                        required: false,
                      })}
                    />
                  }
                />
                {errors.revenues && <span>{t("error-input-required")}</span>}
              </div>
            </div>
            <div className="custom-line-hr"></div>
            <h3 style={{ margin: "0.5rem 0rem 0.5rem 0rem" }}>
              {t("label-Negociation-S4B")}
            </h3>
            <div className="double-fields">
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="fee">
                  {t("S4B FEE")} / {t("year")}
                </label>

                <CurrencyInput
                  currency={currencyProspection}
                  locale={localeProspection}
                  defaultValue={prospectingGeneratorDetails?.fee || ""}
                  onChangeValue={(event, originalValue, maskedValue) => {
                    setValue("fee", maskedValue.toString());
                    setValue("originFee", Number(originalValue));
                  }}
                  InputElement={
                    <input
                      placeholder={`FEE (${currencyProspection})`}
                      type="text"
                      id="fee"
                      {...register("fee", {
                        required: false,
                      })}
                    />
                  }
                />
                {errors.revenues && <span>{t("error-input-required")}</span>}
              </div>
            </div>
            <div className="double-fields">
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="percentualFee">{"S4B FEE %"}</label>
                <input
                  placeholder={t("S4B FEE %")}
                  type="number"
                  id="percentualFee"
                  {...register("percentualFee", {
                    required: true,
                  })}
                />
                {errors.percentualFee && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="lead">{t("Lead")}</label>
                <input
                  placeholder={t("Lead")}
                  type="text"
                  id="lead"
                  {...register("lead", {
                    required: false,
                  })}
                />
              </div>
            </div>

            <div className="custom-line-hr"></div>

            <div className="flex-column">
              <h3 style={{ margin: "10px 0px 10px 0px" }}>
                {t("label-contact")}
              </h3>
              {fields.map((item, index) => (
                <div
                  className="contact-row double-fields margin-topbottom-1"
                  key={item.id}
                >
                  <div className="flex-column">
                    <label htmlFor={`contacts[${index}].name`}>
                      {t("label-name")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("label-name")}
                      id={`contacts[${index}].name`}
                      {...register(`contacts.${index}.name`, {
                        required: true,
                      })}
                    />
                    {errors.contacts?.[index]?.name && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                  <div className="flex-column">
                    <label htmlFor={`contacts[${index}].email`}>
                      {t("label-email")}
                    </label>
                    <input
                      type="email"
                      placeholder={t("placeholder.email")}
                      id={`contacts[${index}].email`}
                      {...register(`contacts.${index}.email`, {
                        required: true,
                      })}
                    />
                    {errors.contacts?.[index]?.email && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                  <div className="flex-column">
                    <label htmlFor={`contacts[${index}].role`}>
                      {t("label-function")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("label-function")}
                      id={`contacts[${index}].role`}
                      {...register(`contacts.${index}.role`, {
                        required: true,
                      })}
                    />
                    {errors.contacts?.[index]?.role && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                  <button
                    type="button"
                    style={{ margin: 0 }}
                    className="neutral-white"
                    disabled={index == 0}
                    onClick={() => remove(index)}
                  >
                    <SparkIcon icName="delete" noPadding></SparkIcon>
                  </button>
                </div>
              ))}
              <button
                type="button"
                style={{ margin: "5px 0px 5px", width: "100%" }}
                onClick={() => append({ name: "", email: "", role: "" })}
              >
                <SparkIcon icName="add"></SparkIcon>
              </button>
            </div>

            <div className="custom-line-hr"></div>

            <div className="flex-row buttons-section">
              <button
                className="neutral-white"
                type="button"
                onClick={() => navigate(`/scrap-generators`)}
              >
                {t("label-back")}
              </button>
              <button className="blue" type="submit" disabled={isDisabled}>
                {t("label-save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    return <NotFound />;
  }
};

export default NewCrmCompanyForm;
