import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { profileENUM } from "../../assets/enums/profiles-enum";

export async function getFinancial(
  profile: string,
  originId: string
): Promise<any> {
  let q;
  if (profile == profileENUM.ADMIN_BOSCH) {
    q = query(collection(firestore, COLLECTIONS.FINANCIAL));
  } else {
    q = query(
      collection(firestore, COLLECTIONS.FINANCIAL),
      where("generatorId", "==", originId)
    );
  }

  const docSnap = await getDocs(q);


 const items = docSnap.docs.map((doc) => {
  const data = doc.data();


  let totalValue = data.totalValue;


  if (isNaN(Number(totalValue)) || totalValue === null || totalValue === "") {
    totalValue = "-"; 
  } else {
    totalValue = Number(totalValue);
  }

 

  return {
    id: doc.id,
    ...data,
    totalValue, 
  };
});

return items; 
}