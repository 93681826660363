import React, { useState } from "react";
import { t } from "i18next";
import { deleteFromFirestore } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
 
interface ModalProps {
  onClose: () => void;
  dataId:any;
}
 
const ModalPopupDelete: React.FC<ModalProps> = ({ onClose, dataId }) => {
    const handleSubmit = () => {
      if (dataId) {
        deleteFromFirestore(dataId.id, COLLECTIONS.COLLECTS);
        onClose();
        window.location.reload();
      } else {
        console.error("Data ID is missing");
      }
    };
 
   
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content-first" onClick={(e) => e.stopPropagation()}>
 
        <h3>{t("label-popup-collect")}</h3>
 
        <div style={{display:"flex", justifyContent:"space-around"}}>
 
        <button onClick={onClose} type="button">
            {t("label-cancel")}
        </button>
           
        <button
          className="primary"
          type="button"
          onClick={() => handleSubmit()}
        >
          {t("label-delete")}
        </button>
        </div>
      </div>
    </div>
  );
};
 
export default ModalPopupDelete;
function setSomeState(arg0: (prevState: any) => boolean) {
    throw new Error("Function not implemented.");
}
 