import { getFirestore, collection, getDocs } from "firebase/firestore";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";

interface Country {
  id: string;
  country: string;
  currency: string;
  currencyFormating: string;
  postalCode: string;
  locale: string;
  postalCodeFormatting: string;
  nationalRegister: string;
  nationalRegisterFormatting: string;
  paymentGateway: string;
}

const fetchCountries = async (): Promise<Country[]> => {
  const db = getFirestore();
  const countryCollection = collection(db, COLLECTIONS.COUNTRY_CONFIG);
  const countrySnapshot = await getDocs(countryCollection);

  const countryList: Country[] = [];
  countrySnapshot.forEach((doc) => {
    const countryData = doc.data();
    const country: Country = {
      id: doc.id,
      country: countryData.country || "",
      currency: countryData.currency || "",
      locale: countryData.locale || "",
      currencyFormating: countryData.currencyFormating || "",
      postalCode: countryData.postalCode || "",
      postalCodeFormatting: countryData.postalCodeFormatting || "",
      nationalRegister: countryData.nationalRegister || "",
      nationalRegisterFormatting: countryData.nationalRegisterFormatting || "",
      paymentGateway: countryData.paymentGateway || "",
    };
    countryList.push(country);
  });

  return countryList;
};

export default fetchCountries;
