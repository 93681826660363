import React from "react";
import industryIcon from "../../../../../assets/svgs/industry.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScrapDealerInfoDTO } from "../../../scrap-dealer-DTO";

interface Props {
  item: ScrapDealerInfoDTO;
}

const CardScrapDealer: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  type HomologationStatus = 'VALIDATED' | 'NOT_COMPLIANT' | 'TO_EXPIRE';
  const colors = {
    "VALIDATED": "#00884a",
    "NOT_COMPLIANT": "#ed0007",
    "TO_EXPIRE": "#ffcf00",
  }

  return (
    <div className="flex-row" style={{height: "130px", width: "100%"}}>
      <div style={{ width: "6px", height: "114px", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", backgroundColor: colors[item.homologationStatus ? item.homologationStatus as HomologationStatus : "NOT_COMPLIANT"] }}></div>
      <div
        className="flex-row card-dealer"
        onClick={() => {
          navigate(`/scrap-dealers-details/${item.id}/register`);
        }}
      >
        <div className="flex-row left">
          <img src={industryIcon} alt="industry-icon" />
          <div className="flex-column infos">
            <h3>{item.tradingName || "-"}</h3>
            <p>
              {t("label-region")}: <strong>{item.region || "-"}</strong>
            </p>
          </div>
        </div>
        <div className="flex-column right">
          <p className="type">{t("label-waste")}</p>
        </div>
      </div>
    </div>
  );
};

export default CardScrapDealer;
