import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ContactForm from "../../credit-analysis-form/credit-analysis-form";
import { CreditAnalysisInfoDTO } from "../../credit-analysis-DTO";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../../../services/address-service";
import { getCreditValue } from "../../credit-analysis-service";
import { calculateFromCurrency } from "../../../../../../services/currency";
import { sendEmailSDM } from "../../../../../../services/send-email-SDM";

interface Props {
  item: CreditAnalysisInfoDTO;
  onClose: () => void;
}

const CardCreditAnalysis: React.FC<Props> = ({ onClose, item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [creditValue, setCreditValue] = useState(() => {
    const storedCredit = localStorage.getItem("creditValue");
    return storedCredit !== null
      ? parseFloat(storedCredit)
      : item?.creditValue ?? 0;
  });

  const [currencyNameEntity, setCurrencyNameEntity] = useState("");
  const [localeEntity, setlocaleEntity] = useState("");
  const [currencyEntity, setcurrencyEntity] = useState("");
  const [currencyFormatting, setcurrencyFormatting] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose();
  };
  useEffect(() => {
    if (id) {
      getAdrressByEntityId(id).then((data) => {
        if (data) {
          getCountryConfigById(data.country).then((data) => {
            if (data) {
              setcurrencyEntity(data.currency);
              setlocaleEntity(data.locale);
              setCurrencyNameEntity(data.currencyName);
              setcurrencyFormatting(data.currencyFormatting);
            }
          });
        }
      });
      getCreditValue(id).then((data) => {
        if (data) {
          setCreditValue(data.inBillingValue);
        }
      });
    }
  }, [currencyEntity, localeEntity, currencyFormatting]);
  return (
    <div>
      {isModalOpen && (
        <div className="modal-background">
          <ContactForm contactId={item.id} onClose={handleCloseModal} />
        </div>
      )}
      <div className="flex-row card-dealer" onClick={handleOpenModal}>
        <div className="flex-column infos">
          <div className="flex-row">
            <h3>
              {t("label-credit-limit-value")}:{" "}
              <strong>{item.limitValue ?? "-"}</strong>
            </h3>
            <span
              style={{ marginLeft: 5, color: "#9e9e9e" }}
            >{`(${currencyNameEntity})`}</span>
          </div>
          <div className="custom-line-hr" />
          {localeEntity && (
            <p>
              {t("label-balance")}:{" "}
              <strong>
                {new Intl.NumberFormat(localeEntity, {
                  style: "currency",
                  currency: currencyEntity,
                }).format(item.balance)}
              </strong>
            </p>

          )}
          <p>
            {t("label-score")}: <strong>{item.score || "-"}</strong>
          </p>
          <p>
            {t("label-status")}: <strong>{item.status || "-"}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardCreditAnalysis;
