import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../utils/firebase-config";
import { getMTRCategoriesApproved } from "../../pages/scraps/scraps-service";
import { MTRCategoryDTO } from "../../pages/scraps/scrap-category/models/scrap-category-DTO";

const MTRCategoryInputAutocomplete: React.FC<{
  label: string;
  placeholder: string;
  onChange: (category: any) => void;
  initialValue?: any;
  callHandlerFirstValue?: boolean;
  isDisabled?: boolean;
}> = ({
  label,
  placeholder,
  onChange,
  callHandlerFirstValue,
  initialValue,
  isDisabled,
}) => {
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(
    initialValue || null
  );
  const [inputValue, setInputValue] = useState("");
  const [userId, setUserId] = useState<string>();
  const [description, setDescription] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId("");
      }
    });
    return () => unsubscribe();
  }, []);

  const handleChangeRef = React.useRef(onChange);
  handleChangeRef.current = onChange;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mtrCategories = await getMTRCategoriesApproved();

        const options = mtrCategories.map((category: MTRCategoryDTO) => ({
          value: category.id,
          label: `${category.code}- ${category.name}`,
          data: category,
        }));

        setCategoryOptions(options);

        if (initialValue) {
          const initialCategory = options.find(
            (option) => option.value === initialValue.id
          );
          if (initialCategory) {
            if (callHandlerFirstValue) {
              onChange(initialCategory.data);
            }
            setSelectedCategory(initialCategory.data);
            handleChangeRef.current(initialCategory.data);
          }
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, [initialValue, userId]);

  const handleChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.data) {
      setSelectedCategory(selectedOption.data);
      setDescription(selectedOption.data.code);
      setInputValue(
        `${selectedOption.data.ibamaCode ?? ""} - ${
          selectedOption.data.nome ?? ""
        }`
      );
      handleChangeRef.current(selectedOption.data);
    } else {
      setSelectedCategory(null);
      setDescription("");
      setInputValue("");
      handleChangeRef.current(null);
    }
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const customFilterOptions = (candidate: any, input: string) => {
    return (
      candidate?.label?.toLowerCase().includes(input?.toLowerCase()) ||
      candidate?.data?.ibamaCode?.toString().includes(input)
    );
  };

  const customStyles: StylesConfig<any, false> = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
      backgroundColor: state.isSelected ? "#e0e0e0" : "white",
    }),
  };

  return (
    <div className="">
      <div className="">
        <label htmlFor="category">{label}</label>
      </div>
      <div style={{ margin: "0.5rem 0rem" }}>
        <Select
          id="category"
          placeholder={placeholder}
          value={
            selectedCategory
              ? {
                  value: selectedCategory.id,
                  label: `${selectedCategory.code} - ${selectedCategory.name}`,
                }
              : null
          }
          onChange={handleChange}
          onInputChange={(newValue, { action }) => {
            if (action === "input-change") {
              handleInputChange(newValue);
            }
          }}
          options={categoryOptions}
          filterOption={customFilterOptions}
          isDisabled={isDisabled}
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export default MTRCategoryInputAutocomplete;
