import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { getScrapGeneratorById } from "../../scrap-generator-service";
import { ScrapGeneratorDTO } from "../../scrap-generators-DTO";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth-context";

const DropdownMenu = ({ show, onClose, options, position }: any) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose(null);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div
      ref={menuRef}
      className="dropdown-menu"
      style={{ top: position.top, left: position.left }}
    >
      <ul>
        {options.map((option: any, index: any) => (
          <li key={index} onClick={() => onClose(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ScrapGeneratorDetails: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc, loading } = useAuth();
  const profile = userDoc?.profile;
  const [scrapGenerator, setScrapGenerator] =
    useState<ScrapGeneratorDTO | null>(null);
  const [tabs] = useState([
    { value: "register", label: t("label-register") },
    { value: "scraps", label: t("label-waste") },
    { value: "dealers", label: t("label-scrap-dealers") },
    // { value: "", label: t("label-contract") },
    { value: "collections-generator", label: t("label-handling") },
    { value: "others", label: t("label-others") },
  ]);
  const [tempTabs, setTempTabs] = useState<any[]>(tabs);
  const [otherOptions, setOtherOptions] = useState([
    { value: "fee", label: t("label-fee") },          
    { value: "users", label: t("label-users") },      
    { value: "approve-roll", label: t("label-operation-roll") },
    { value: "sigor", label: t("label-sigor") },      
    { value: "documents", label: t("label-documents") }, 
    { value: "contacts", label: t("label-contacts") }, 
    { value: "optimization", label: t("label-opti") }, 
    { value: "branchs", label: t("label-branch") }   
]);


  const [selectedTab, setSelectedTab] = useState("register");
  const [previousTab, setPreviousTab] = useState("register");
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const fetchScrapGenerator = async () => {
      if (!id) return;
      try {
        const data = await getScrapGeneratorById(id);
        if (data) {
          setScrapGenerator(data);
        } else {
          console.error("Data is undefined");
        }
      } catch (error) {
        console.error("Error fetching scrap Generator:", error);
      }
    };

    fetchScrapGenerator();
  }, [id]);

  useEffect(() => {
    const currentPath = location.pathname.split("/").pop();
    const allOptions = [...tempTabs, ...otherOptions];
    const matchedOption = allOptions.find(
      (option) => option.value === currentPath
    );
    if (matchedOption) {
      setSelectedTab(matchedOption.value);
      if (matchedOption.value === "others") {
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    }
  }, [location]);

  const handleTabChange = (event: Event, data: { value: string }) => {
    const newTab = data.value;

    if (newTab === "others") {
      setPreviousTab(selectedTab);
      const target = (event.target as HTMLElement).getBoundingClientRect();
      setDropdownPosition({
        top: target.bottom + window.scrollY,
        left: target.left + window.scrollX,
      });
      setShowDropdown(true);
    } else {
      setTempTabs([]);
      setTempTabs(tabs);
      setSelectedTab(newTab);
      setShowDropdown(false);
      navigate(newTab);
    }
  };

  const handleCloseDropdown = (
    option: { value: string; label: string } | null
  ) => {
    if (option && option.value != "others") {
      setTempTabs([]);
      setTempTabs(
        tabs
          .filter((tab) => tab.value !== option.value && tab.value !== "others")
          .concat(option, { value: "others", label: t("label-others") })
      );
      setSelectedTab(option.value);
      navigate(option.value);
    } else {
      setSelectedTab(previousTab);
    }
    setShowDropdown(false);
  };

  const filterOtherOptions = (otherOptions: any) => {
    if (scrapGenerator?.scrapGeneratorInfo.isHeadquarter === false) {
      otherOptions = otherOptions.filter(
        (tab: { value: string }) => tab.value !== "branchs"
      );
    }
    return otherOptions;
  };

  if (loading) return null;

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page page-centered">
        <h2>{scrapGenerator?.scrapGeneratorInfo.tradingName}</h2>
        <SparkTabNavigation
          items={tempTabs}
          selectedTab={selectedTab}
          whenChange={handleTabChange}
        />
        <Outlet />
        <DropdownMenu
          show={showDropdown}
          onClose={handleCloseDropdown}
          options={filterOtherOptions(otherOptions)}
          position={dropdownPosition}
        />
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapGeneratorDetails;
