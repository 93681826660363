import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import CardListScrapGenerator from "./components/cardlist-scrap-generator/cardlist-scrap-generator";
import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import { useEffect, useState } from "react";
import FilterComponent from "../../components/FIlter/filter";
import { useAuth } from "../../contexts/auth-context";
import {
  getProspectingGenerators,
  getScrapGenerators,
} from "./scrap-generator-service";
import { ScrapGeneratorInfoDTO } from "./scrap-generators-DTO";
import addIcon from "../../assets/svgs/add.svg";
import CardListScrapProspectingGenerator from "./components/cardlist-prospecting/cardlist-prospecting";
import { ScrapProspectingGeneratorsDTO } from "./scrap-generator-prospecting-DTO";
import { getCRMSteps } from "../settings/components/CRM/crm-service";
import { CRMFunnelDTO } from "../settings/components/CRM/crm-dto";

const ScrapGenerators: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams();
  const [filteredCRMData, setFilteredCRMData] = useState<
    ScrapProspectingGeneratorsDTO[]
  >([]);
  const [CRMSteps, setCRMSteps] = useState<CRMFunnelDTO[]>([]);
  const [filteredData, setFilteredData] = useState<ScrapGeneratorInfoDTO[]>([]);
  const [scrapGenerators, setScrapGenerators] = useState<
    ScrapGeneratorInfoDTO[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<string>("1");

  const handleFilterClick = (
    data: any[],
    setter: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    setter(data);
  };

  const sortByRegion = (data: ScrapGeneratorInfoDTO[]) => {
    return [...data].sort((a, b) => {
      if (a.region < b.region) return -1;
      if (a.region > b.region) return 1;
      return 0;
    });
  };

  const sortByLegalName = (data: ScrapGeneratorInfoDTO[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.legalName?.toString().toLowerCase() || "";
      const nameB = b.legalName?.toString().toLowerCase() || "";
      return nameA.localeCompare(nameB);
    });
  };

  const sortOptions = [
    { label: t("label-region"), sortFunction: sortByRegion },
    { label: t("label-legal-name"), sortFunction: sortByLegalName },
  ];

  const tabs = [
    { value: "1", label: t("label-active") },
    { value: "2", label: t("label-prospecting") },
  ];

  const fetchScrapGenerators = async () => {
    try {
      const data = await getScrapGenerators();
      setScrapGenerators(data);
      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching scrap Generators: ", error);
    }
  };

  const fetchPropectingList = async () => {
    const scrapProspectingGenerators = await getProspectingGenerators();
    setFilteredCRMData(scrapProspectingGenerators);
  };

  const fetchCRMSteps = async () => {
    const steps = await getCRMSteps();
    setCRMSteps(steps);
  };

  const mergeCRMData = async () => {
    if (!filteredCRMData || !CRMSteps) {
      console.error("Dados não disponíveis");
      return;
    }

    const funnelInfoMap: Record<
      string,
      (typeof CRMSteps)[0]["crmFunnelInfoDTO"]
    > = CRMSteps.reduce((map, item) => {
      map[item.id] = item.crmFunnelInfoDTO;
      return map;
    }, {} as Record<string, (typeof CRMSteps)[0]["crmFunnelInfoDTO"]>);

    const mergedData = filteredCRMData.map((item) => {
      const existingStepIds = new Set(
        item.CRMSteps.map((step) => step.originId)
      );

      const updatedSteps = [
        ...item.CRMSteps.map((step) => {
          if (step.originId && funnelInfoMap[step.originId]) {
            return {
              ...step,
              crmFunnelInfoDTO: funnelInfoMap[step.originId],
            };
          }
          return step;
        }),
        ...CRMSteps.filter((step) => !existingStepIds.has(step.id)).map(
          (step) => ({
            originId: step.id,
            crmFunnelInfoDTO: funnelInfoMap[step.id] || null,
          })
        ),
      ].sort(
        (a, b) =>
          (a.crmFunnelInfoDTO?.order || 0) - (b.crmFunnelInfoDTO?.order || 0)
      );

      return {
        ...item,
        CRMSteps: updatedSteps,
      };
    });
    setFilteredCRMData(mergedData);
  };

  const fetchData = async () => {
    await Promise.all([fetchPropectingList(), fetchCRMSteps()]);
    if (CRMSteps.length > 0 && filteredCRMData.length > 0) {
      mergeCRMData();
    }
  };

  useEffect(() => {
    fetchScrapGenerators();
    fetchData();
  }, []);

  const handleCloseModal = () => {
    fetchScrapGenerators();
  };

  const handleTabChange = (event: Event, data: { value: string }) => {
    const newTab = data.value;
    setSelectedTab(newTab);
    fetchScrapGenerators();
    fetchData();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        <SparkTabNavigation
          items={JSON.stringify(tabs)}
          selectedTab={selectedTab}
          whenChange={handleTabChange}
        />

        {selectedTab == "2" ? (
          <div>
            <div className="flex-row" style={{ marginBottom: 15 }}>
              <button
                className="blue-with-icon button"
                onClick={() => navigate("/new-crm-company")}
              >
                <img src={addIcon} alt="add icon" />
                <p>{t("label-add-new")}</p>
              </button>

              <FilterComponent
                data={filteredCRMData}
                onFilterClick={(data) =>
                  handleFilterClick(data, setFilteredCRMData)
                }
              />
            </div>
            <div>
              <CardListScrapProspectingGenerator
                scrapProspectingGenerators={filteredCRMData}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="flex-row" style={{ marginBottom: 15 }}>
              <button
                className="blue-with-icon button"
                onClick={() => navigate("/scrap-generators-new")}
              >
                <img src={addIcon} alt="add icon" />
                <p>{t("label-add-new")}</p>
              </button>

              <ImportExportCsv
                exportApi="exportscrapgeneratorscsv"
                importApi="importscrapgeneratorscsv"
                onClose={handleCloseModal}
              />

              <FilterComponent
                data={scrapGenerators}
                onFilterClick={(data) =>
                  handleFilterClick(data, setFilteredData)
                }
                sortOptions={sortOptions}
              />
            </div>
            <CardListScrapGenerator scrapGenerators={filteredData} />
          </div>
        )}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapGenerators;
