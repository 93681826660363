import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";

import list from "../../../assets/img/list-view.svg";
import card from "../../../assets/img/components.svg";
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../utils/firebase-config";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import EcommerceCard from "../../../components/EcommerceCard/EcommerceCard";
import Slider from "../../../components/Slider/Slider";
import NoData from "../../../components/NoData/NoData";
import { useAuth } from "../../../contexts/auth-context";
import FilterComponent from "../../../components/FIlter/filter";
import { colorsCardStatus } from "../../../assets/enums/colors-card-status";
import Tooltip from '../../../components/tooltip/tooltip';

const ScrapAdminScrapsContractedView: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [slideData, setSliderData] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const data = { generatorId: id };
  const [tableData, setTableData] = useState<any>([]);
  const tableHeader = [
    `${t("label-material")}`,
    `${t("label-scrap-generator")}`,
    `${t("label-scrap-dealer")}`,
    `${t("label-quantity")}`,
    `${t("label-value")}`,
  ];
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleFilterClick = (filteredData: any[]) => {
    setFilteredData(filteredData);
  };

  const sortByRegion = (data: any[]) => {
    return [...data].sort((a, b) => {
      const regionA = a.scrapCategory.name?.toString().toLowerCase() || '';
      const regionB = b.scrapCategory.name?.toString().toLowerCase() || '';
      return regionA.localeCompare(regionB);
    });
  };



  const sortByLegalName = (data: any[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.contractDealerName?.toString().toLowerCase() || '';
      const nameB = b.contractDealerName?.toString().toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });
  };
  const sortByorigin = (data: any[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.originName?.toString().toLowerCase() || '';
      const nameB = b.originName?.toString().toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });
  };

  const sortOptions = [
    { label: t("label-material"), sortFunction: sortByRegion },
    { label: t("label-scrap-dealer"), sortFunction: sortByLegalName },
    { label: t("label-scrap-generator"), sortFunction: sortByorigin },

  ];

  const handleSortClick = (sortFunction: (data: any[]) => any[]) => {
    const sortedData = sortFunction([...filteredData]); // Ordenar os dados filtrados
    setFilteredData(sortedData);
  };

  const fetchTableData = async () => {
    const qContracted = query(
      collection(firestore, "ScrapByGenerator"),
      where("status", "==", "CONTRACTED"),
    );

    const qOperating = query(
      collection(firestore, "ScrapByGenerator"),
      where("status", "==", "OPERATING"),
    );

    const querySnapshotContracted = await getDocs(qContracted);
    const querySnapshotOperating = await getDocs(qOperating);


    const combinedQuerySnapshot = [
      ...querySnapshotContracted.docs,
      ...querySnapshotOperating.docs,
    ];

    const items = combinedQuerySnapshot.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc
        .data()
      ["scrapCategory"]["name"].concat(
        " ",
        doc.data()["scrapCategory"]["description"],
        " ",
        doc.data()["specialDescription"],
        " ",
        doc.data()["originName"],
        " ",
        doc.data()["contractDealerName"],
        " ",
        doc.data()["operationAddress"]["city"],
        " ",
        doc.data()["operationAddress"]["state"]
      ),
    })) as unknown as any[];
    setTableData(items);
    setFilteredData(items);
  };

  useEffect(() => {
    fetchTableData();
  }, [id]);

  const handleCloseModal = () => {
    fetchTableData();
  };
  const cellStyle = {
    padding: "5px",

    maxWidth: "180px",

    whiteSpace: "normal",
  };
  if (enabledUsers.includes(profile || "public")) {
    return (
      <div style={{ marginBottom: "120px" }}>
        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="flex-row">
            <ImportExportCsv
              exportApi="exportscrapbygeneratorscsv"
              importApi=""
              onClose={handleCloseModal}
            />
          </div>
          <FilterComponent
            data={tableData}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}

          />

          <Slider
            options={[
              { display: list, value: "list", viewType: "image" },
              { display: card, value: "card", viewType: "image" },
            ]}
            setState={setSliderData}
          ></Slider>
        </div>

        <br />
        <br />
        {tableData.length === 0 && <NoData />}

        {tableData.length > 0 &&
          (slideData === "list" ? (
            <>
              <div className="info-section">
                <SparkTable>
                  <SparkTableHead>
                    <SparkTableRow>
                      <SparkTableCell className="icon-column"></SparkTableCell>
                      {tableHeader.map((header, index) => (
                        <SparkTableCell key={index}>
                          <strong>{header.toUpperCase()}</strong>
                        </SparkTableCell>
                      ))}
                    </SparkTableRow>
                  </SparkTableHead>

                  <SparkTableBody>
                    {filteredData.map((rowItem: any, rowIndex: any) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={() =>
                          navigate(
                            `/scrap-generators-details/${rowItem.originId}/scraps-form/${rowItem.id}`
                          )
                        }
                      >
                        <SparkTableCell style={{ ...cellStyle, }}>
                          <Tooltip
                            text={
                              rowItem.scrapCategory.isHazardousMaterial
                                ? t("label-hazardous-material")
                                : t("label-non-hazardous-material")
                            }
                          >
                            <div>
                              {rowItem.scrapCategory.isHazardousMaterial && (
                                <div >

                                  <SparkIcon

                                    className="icon-flag"
                                    no-padding={true}
                                    icName={"danger-hazards"}
                                  />
                                </div>
                              )}
                              <p style={{ marginLeft: "25px" }}>
                                {rowItem.scrapCategory.name}
                              </p>
                            </div>
                          </Tooltip>
                        </SparkTableCell>
                        <SparkTableCell style={{ ...cellStyle, }}>{rowItem.originName}</SparkTableCell>
                        <SparkTableCell style={{ ...cellStyle, }}>
                          {rowItem.contractDealerName}
                        </SparkTableCell>
                        <SparkTableCell style={{ ...cellStyle, }}>
                          {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/
                          {t(`periodENUM.${rowItem.period}`)}
                        </SparkTableCell>
                        <SparkTableCell style={{ ...cellStyle, }}>
                          {(rowItem.status == "CONTRACTED" || rowItem.status == "OPERATING") ? rowItem.contractValue : rowItem.targetPrice}/
                          {t(`unityENUM.${rowItem.unity}`)}
                        </SparkTableCell>
                      </SparkTableRow>
                    ))}
                  </SparkTableBody>
                </SparkTable>
              </div>


            </>
          ) : (
            <div className="eccomerce-card-wrapper">
              {filteredData.map((rowItem: any, rowIndex: any) => (
                <EcommerceCard
                  onClick={() =>
                    navigate(`/scrap-generators-details/${rowItem.originId}/scraps-form/${rowItem.id}`)
                  }
                  buttonAction={() => { }}
                  buttontext="Visualizar"
                  imageUrl={
                    rowItem.images?.length > 0
                      ? rowItem.images
                      : rowItem.scrapCategory.image || "Image not found!"
                  }
                  price={
                    (rowItem.status === "CONTRACTED" || rowItem.status === "OPERATING")
                      ? rowItem.contractValue
                      : rowItem.targetPrice ?? ""
                  }
                  unitPrice={
                    rowItem.targetPrice ? t(`unityENUM.${rowItem.unity}`) : ""
                  }
                  key={rowIndex}
                  customBottom={
                    <div className="flex-row align-centercard">
                      <Tooltip
                        text={
                          rowItem.scrapCategory.isHazardousMaterial
                            ? t("label-hazardous-material")
                            : t("label-non-hazardous-material")
                        }
                      >
                        <SparkIcon
                          no-padding={true}
                          icName={
                            rowItem.scrapCategory.isHazardousMaterial
                              ? "danger-hazards"
                              : "less-minimize"
                          }
                        />
                      </Tooltip>
                      <span style={{ marginLeft: 15,  whiteSpace: "nowrap",  }}>
                        {rowItem.scrapCategory.isHazardousMaterial
                          ? t("label-hazardous-material")
                          : t("label-non-hazardous-material")}
                      </span>
                      <div
                        style={{
                          backgroundColor: colorsCardStatus[rowItem.status],
                          padding: "0px 10px",
                          height: '100%',
                          marginLeft: 20,
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {t(`statusEnum.${rowItem.status}`)}
                      </div>
                    </div>
                  }
                >
                  <div>
                    <h3>{rowItem.scrapCategory.name}</h3>
                    <p style={{ marginTop: 7 }}>
                      {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/
                      {t(`periodENUM.${rowItem.period}`)}
                    </p>
                    <p style={{ marginTop: 7 }}>
                      {t(`withdrawal`)} {t(`periodENUM.${rowItem.pickUpFrequency}`)}
                    </p>
                    <p style={{ marginTop: 7 }}>
                      {rowItem.operationAddress.city} - {rowItem.operationAddress.countryName}
                    </p>
                    <strong>
                      <p style={{ marginTop: 7 }}>{rowItem.contractDealerName}</p>
                    </strong>
                  </div>
                </EcommerceCard>
              ))}
            </div>
          ))}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapAdminScrapsContractedView;
