import React, { useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import { addNewCDF } from "../../../collects-service";
import { useAlert } from "../../../../../contexts/alert-context";
import {
  SparkIcon,
  SparkTableBody,
  SparkTableCell,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { extractFileNameFromUrl } from "../../../../../services/string-treatment";
import { applyDecimalMask } from "../../../../../utils/formatting";
import genericImage from "../../../../../assets/svgs/material-list.svg";
import { CDFDTO } from "./cdf-dto";
import ScrapCategoryInputAutocomplete from "../../../../../components/ScrapCategoryInputAutocomplete/ScrapCategoryInputAutocomplete";
import UploadFileOrImage from "../../../../../components/UploadFileAndImagesComponent/UploadFileOrImagesComponent";

const ModalCDF: React.FC<{
  mtrID: string;
  material: any;
  onClose: () => void;
  defaultValue?: CDFDTO;
  transationData: any;
}> = ({ mtrID, material, onClose, defaultValue, transationData }) => {
  const { setAlert } = useAlert();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedCategoryDetails, setSelectedCategoryDetails] = useState<any>(
    {}
  );
  let date = new Date();
  if (!defaultValue) {
    date = new Date(
      transationData.date.seconds * 1000 +
        transationData.date.nanoseconds / 1000000
    );
  }
  const [formData, setFormData] = useState<CDFDTO>(defaultValue || {
    mtr: mtrID,
    scrapMaterialName: "",
    date: new Date(),
    cdfNumber: "",
    material: material,
    attachments: [],
    receivedQtd: 0,
    dealerId: transationData.contractedDealerId,
    dealerName: transationData.scrapDealerName,
    generatorId: transationData.generatorId,
    generatorName: transationData.scrapGeneratorName,
    isHazardousMaterial: transationData.material?.isHazardousMaterial ?? false
  });

  function onChange(key: keyof CDFDTO, value: any) {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  }

  const [removedFiles, setRemovedFiles] = useState<string[]>([]);

  const handleImageChange = useCallback(
    (files: File[], removedFiles: string[]) => {
      setSelectedFiles(files);
      if (removedFiles.length > 0) {
        setRemovedFiles(removedFiles);
      }
    },
    []
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(".", "");
    const masked = applyDecimalMask(value);
    onChange("receivedQtd", masked);
  };

  async function save(data: CDFDTO) {
    data.receivedQtd = Number(data.receivedQtd.toString().replace(".", ""));
    try {
      await addNewCDF(data, mtrID, selectedFiles,removedFiles).then(() => {
        setAlert({
          text: t("alert-successfully-registered"),
          type: "success",
        });
        setTimeout(onClose, 2000);
      });
    } catch (error) {
      setAlert({
        text: `${t("alert-error-occurred")}: ${error}`,
        type: "error",
      });
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setFormData(defaultValue);
    }
    onChange(
      "receivedQtd",
      applyDecimalMask(`${formData.receivedQtd}`.replace(".", ""))
    );
  }, [defaultValue]);

  return (
    <div className="modal-content" style={{ gap: 15 }}>
      <ScrapCategoryInputAutocomplete
        onChange={(value) => setSelectedCategoryDetails(value)}
        label=""
        placeholder=""
        initialValue={formData.material}
        callHandlerFirstValue={true}
        isDisabled
      ></ScrapCategoryInputAutocomplete>

      {selectedCategoryDetails && (
        <div className="flex-row info-section">
          <img
            style={{
              height: "6rem",
              width: "8rem",
              overflow: "hidden",
              marginRight: "1rem",
            }}
            src={
              selectedCategoryDetails.images
                ? selectedCategoryDetails.images[selectedCategoryDetails.images.length-1]
                : genericImage
            }
            alt="material image"
          />
          <div style={{ width: "100%" }} className="flex-column">
            <h2>{selectedCategoryDetails.name}</h2>
            <div className="custom-line-hr"></div>
            <div className="flex-row justify-between card-scrap-basic-info">
              <div className="flex-column" style={{ width: "100%" }}>
                <p style={{ wordWrap: "break-word" }}>
                  {t("label-net-weight")}:{" "}
                  {applyDecimalMask(`${formData.material.quantity}`)}{" "}
                  {t(`unityENUM.${formData.material?.unity}`)}
                </p>
                <p
                  className="flex-row"
                  style={{
                    width: "100%",
                    justifyContent: "flex-end",
                    fontSize: "10px",
                    marginTop: "1rem",
                  }}
                >
                  {/* {t("date-collected")}: {transationData.date} */}
                </p>
              </div>

              <div
                className="flex-column"
                style={{ gap: "10px", maxWidth: "80%" }}
              ></div>
              <div
                className="flex-column"
                style={{ gap: "10px", maxWidth: "80%" }}
              ></div>
            </div>
          </div>
        </div>
      )}
      <div className="flex-column" style={{ gap: 8 }}>
        <label htmlFor="cdfNumber">CDF</label>
        <input
          id="cdfNumber"
          value={formData.cdfNumber}
          placeholder={t("label-cdf-number")}
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange("cdfNumber", e.target.value);
          }}
        />
      </div>

      <div className="flex-column" style={{ gap: 8 }}>
        <label htmlFor="receivedQtd">
          {t("label-received-indicated-quantity")} (
          {t(`unityENUM.${formData.material?.unity}`)})
        </label>
        <input
          id="receivedQtd"
          value={formData.receivedQtd}
          placeholder={t("label-received-indicated-quantity")}
          type="text"
          onChange={handleInputChange}
        />
      </div>


        <>

          <div className="flex-column">
            
            <UploadFileOrImage
              onlyImages={false}
              onSave={(itens, removedFiles) =>
                handleImageChange(itens, removedFiles)
              }
              initialSavedFiles={formData?.attachments}
              
            />
           
          </div>

         
        </>


      <div
        className="flex-row justify-end"
        style={{ gap: "10px", marginTop: "1.5rem" }}
      >
        <button onClick={onClose} style={{ margin: 0 }}>
          {t("label-cancel")}
        </button>
        <button
        className="blue"
        style={{ margin: 0 }}
        onClick={() => {
          save({ ...formData });

          setTimeout(() => {
            window.location.reload();
          }, 4500); 
        }}
      >
        {t("label-save")}
      </button>
      </div>
    </div>
  );
};

export default ModalCDF;
