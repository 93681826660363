import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  addNewScrapDealer,
  getScrapDealerById,
} from "../../scrap-dealers-service";
import { ScrapDealerDTO } from "../../scrap-dealer-DTO";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../../contexts/alert-context";
import { useState, useRef, useEffect } from "react";
import InputMask from "react-input-mask";
import CountryInputAutocomplete from "../../../../components/CountryInputAutocomplete/CountryInputAutocomplete";
import HeadquarterInputAutocomplete from "../../../../components/HeadquarterInputAutocomplete/HeadquarterInputAutocomplete";
import { HeadquarterDTO } from "../../../../components/HeadquarterInputAutocomplete/models/headquarter-DTO";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { useAuth } from "../../../../contexts/auth-context";
import { taxationTypesENUM } from "../../../../assets/enums/taxation-type-enum";
import { t } from "i18next";
import { CountryConfigDTO } from "../../../settings/components/Country-config/country-config-DTO";
import { callFunction } from "../../../../services/persistence";

interface BusinessTypeSelectorProps {
  handleChange: (value: boolean) => void;
  actualValue: boolean;
}

export const BusinessTypeSelector: React.FC<BusinessTypeSelectorProps> = ({
  handleChange,
  actualValue,
}) => {
  const [isFinal, setIsFinal] = useState(actualValue);

  const onChange = (value: boolean) => {
    setIsFinal(value);
    handleChange(value);
  };

  return (
    <div className="flex-column">
      <label>{t("label-business-type")}</label>
      <div className="flex-row radio-types">
        <div className="flex-row">
          <label htmlFor="isFinal">
            <input
              type="radio"
              id="isFinal"
              value="true"
              onChange={() => onChange(true)}
              checked={isFinal}
            />
            {t("label-type-isFinal")}
          </label>
        </div>
        <div className="flex-row">
          <label htmlFor="isCarrier">
            <input
              type="radio"
              id="isCarrier"
              value="false"
              onChange={() => onChange(false)}
              checked={!isFinal}
            />
            {t("label-type-isCarrier")}
          </label>
        </div>
      </div>
    </div>
  );
};

const NewScrapDealerForm: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const { headquarterParamId } = useParams<{ headquarterParamId: string }>();
  const { setAlert } = useAlert();
  const [scrapDealer, setScrapDealer] = useState<ScrapDealerDTO | null>(null);
  const [countryError, setCountryError] = useState("");
  const [headquarterError, setHeadquarterError] = useState("");
  const [selectedCountryDetails, setSelectedCountryDetails] =
    useState<CountryConfigDTO | null>(null);
  const [nationalRegisterLabel, setNationalRegisterLabel] = useState<string>(
    "label-national-register"
  );
  const [nationalRegisterFormat, setNationalRegisterFormat] =
    useState<string>("99.999.999/9999-99");
  const [postalCodeLabel, setPostalCodeLabel] =
    useState<string>("label-postal-code");
  const [postalCodeFormat, setPostalCodeFormat] =
    useState<string>("99.999-999");
  const [isDisabled, setIsDisabled] = useState(false);
  const [mtrGeneratorNumber, setMtrGeneratorNumber] = useState<string>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<ScrapDealerDTO>();

  const nationalRegisterRef = useRef(null);
  const postalCodeRef = useRef(null);
  const phoneRef = useRef(null);
  const [isHeadquarter, setIsHeadquarter] = useState(
    scrapDealer?.scrapDealerInfo?.isHeadquarter ?? true
  );

  const [isFinal, setIsFinal] = useState(
    scrapDealer?.scrapDealerInfo?.isFinal ?? true
  );

  useEffect(() => {
    if (headquarterParamId) {
      getScrapDealerById(headquarterParamId).then((data) => {
        if (data) {
          setScrapDealer(data);
          setIsHeadquarter(false);
          setIsFinal(true);
          setValue("scrapDealerInfo.isFinal", true, {
            shouldValidate: true,
          });

          setValue("scrapDealerInfo.isHeadquarter", false, {
            shouldValidate: true,
          });
          setValue("scrapDealerInfo.headquarterId", headquarterParamId, {
            shouldValidate: true,
          });
          setValue(
            "scrapDealerInfo.headquarterName",
            data.scrapDealerInfo.tradingName,
            {
              shouldValidate: true,
            }
          );
        }
      });
    } else if (id) {
      getScrapDealerById(id).then((data) => {
        if (data) {
          setScrapDealer(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof ScrapDealerDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof ScrapDealerDTO, value as any);
            }
          });
          const isHeadquarterValue = data.scrapDealerInfo.isHeadquarter;
          setIsHeadquarter(isHeadquarterValue);
          setValue("scrapDealerInfo.isHeadquarter", isHeadquarterValue, {
            shouldValidate: true,
          });

          const isFinalValue = data.scrapDealerInfo.isFinal;
          setIsFinal(isFinalValue);
          setValue("scrapDealerInfo.isFinal", isFinalValue, {
            shouldValidate: true,
          });
        }
      });
    }
  }, [id, setValue]);

  const clearFormFields = () => {
    setValue("scrapDealerInfo.nationalRegister", "");
    setValue("scrapDealerInfo.legalName", "");
    setValue("scrapDealerInfo.segment", "");
    setValue("addressInfo.postalCode", "");
    setValue("addressInfo.city", "");
    setValue("addressInfo.state", "");
    setValue("addressInfo.address", "");
    setValue("scrapDealerInfo.mtrCode", "");
    setMtrGeneratorNumber("");
  };

  const handleCountryChange = async (country: CountryConfigDTO) => {
    if(!id){
      await clearFormFields();
    }
    setSelectedCountryDetails(country);
    setNationalRegisterLabel(
      country.nationalRegister || "label-national-register"
    );
    setNationalRegisterFormat(
      country.nationalRegisterFormatting || "99.999.999/9999-99"
    );
    setPostalCodeLabel(country.postalCode || "label-postal-code");
    setPostalCodeFormat(country.postalCodeFormatting || "99.999-999");
    setValue("addressInfo.country", country.id, { shouldValidate: true });
    setValue("addressInfo.countryName", country.country[0], {
      shouldValidate: true,
    });
    setValue("addressInfo.postalCodeLabel", country.postalCode, {
      shouldValidate: true,
    });
    setValue(
      "scrapDealerInfo.nationalRegisterLabel",
      country.nationalRegister,
      { shouldValidate: true }
    );
  };

  const handleInputToAutocompleteForm = (e: any) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    if (nationalRegisterLabel == "CNPJ" && rawValue.length === 14) {
      callFunction("getunitymtr", { cnpj: rawValue })
        .then((mtr: any) => {
          if (mtr) {
            setValue(
              "scrapDealerInfo.mtrCode",
              mtr.data.objetoResposta[0].parCodigo
            );
            setMtrGeneratorNumber(mtr.data.objetoResposta[0].parCodigo);
          } else {
            console.warn("Nenhum MTRCode encontrado para o CNPJ informado.");
          }
        })
        .catch((error) => {
          console.error("Erro ao chamar getunitymtr:", error);
        });

      callFunction("getcardcnpj", { cnpj: rawValue })
        .then((data: any) => {
          if (data) {
            setValue("scrapDealerInfo.legalName", data?.data?.nome);
            setValue(
              "scrapDealerInfo.segment",
              data?.data?.atividade_principal[0].text
            );
            const cep = data?.data?.cep.replace(/\D/g, "");
            setValue("addressInfo.postalCode", cep);
            setValue("addressInfo.city", data?.data?.municipio);
            setValue("addressInfo.state", data?.data?.uf);
            const address = `${data?.data?.bairro}, ${data?.data?.logradouro}, ${data?.data?.numero}, ${data?.data?.complemento}`;
            setValue("addressInfo.address", address);

            trigger("addressInfo.postalCode");
          }
        })
        .catch((error) => {
          console.error("Erro ao chamar getcardcnpj:", error);
        });
    }
  };

  const handleHeadquarterChange = (headquarter: HeadquarterDTO) => {
    handleRadioChange(false);
    setValue("scrapDealerInfo.headquarterId", headquarter.id, {
      shouldValidate: true,
    });
    setValue("scrapDealerInfo.headquarterName", headquarter.tradingName, {
      shouldValidate: true,
    });
  };

  const onSubmit = async (scrapDealer: ScrapDealerDTO) => {
    setIsDisabled(true);

    if (!selectedCountryDetails) {
      setCountryError("true");
    } else {
      if (!isHeadquarter && !scrapDealer.scrapDealerInfo.headquarterName) {
        setHeadquarterError("true");
      } else {
        setCountryError("");
        setHeadquarterError("");
        setValue("scrapDealerInfo.isHeadquarter", isHeadquarter, {
          shouldValidate: true,
        });

        scrapDealer.scrapDealerInfo.isHeadquarter = isHeadquarter === true;
        scrapDealer.scrapDealerInfo.isFinal = isFinal === true;

        try {
          await addNewScrapDealer(scrapDealer);
          setAlert({
            text: t("alert-successfully-registered"),
            type: "success",
          });
          navigate("/scrap-dealers");
        } catch (e) {
          console.error("Erro ao cadastrar: ", e);
          setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
        }
      }
    }
    setIsDisabled(false);
  };

  const handleSelect = (value: boolean) => {
    setIsHeadquarter(value);
  };

  const handleRadioChange = (targetValue: boolean) => {
    setIsHeadquarter(targetValue);
  };

  const handleRadioChangeFinal = (targetValue: boolean) => {
    setIsFinal(targetValue);
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page-centered">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-fields flex-column">
            <h2>{t("label-business-information")}</h2>

            <div className="flex-column">
              <CountryInputAutocomplete
                label={`${t("label-country")}*`}
                placeholder={t("placeholder.country")}
                register={register}
                errors={errors}
                onChange={handleCountryChange}
                initialValue={scrapDealer?.addressInfo?.countryName}
                isDisabled={!!scrapDealer && !headquarterParamId}
              />
              {!selectedCountryDetails && countryError && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label>{t("label-type")}</label>
              <div className="flex-row radio-types">
                <div className="flex-row">
                  <label htmlFor="isHeadquarter">
                    <input
                      type="radio"
                      id="isHeadquarter"
                      value="true"
                      onChange={(e) => handleRadioChange(true)}
                      checked={isHeadquarter === true}
                      defaultChecked={
                        scrapDealer?.scrapDealerInfo.isHeadquarter === true
                      }
                      disabled={!!scrapDealer}
                    />
                    {t("label-headquarter")}
                  </label>
                </div>
                <div className="flex-row">
                  <label htmlFor="isBranch">
                    <input
                      type="radio"
                      id="isBranch"
                      value="false"
                      onChange={() => handleRadioChange(false)}
                      checked={isHeadquarter === false}
                      defaultChecked={
                        scrapDealer?.scrapDealerInfo.isHeadquarter === false
                      }
                      disabled={!!scrapDealer}
                    />
                    {t("label-branch")}
                  </label>
                </div>
                {errors.scrapDealerInfo?.isHeadquarter === null &&
                  scrapDealer?.scrapDealerInfo.isHeadquarter && (
                    <span>{t("error-input-required")}</span>
                  )}
              </div>
            </div>

            {isHeadquarter === false && (
              <div className="flex-column">
                <HeadquarterInputAutocomplete
                  label={t("label-headquarter-name")}
                  placeholder={t("placeholder.legalName")}
                  register={register}
                  errors={errors}
                  onChange={handleHeadquarterChange}
                  initialValue={scrapDealer?.scrapDealerInfo}
                  headQuarterCollection={COLLECTIONS.SCRAP_DEALERS}
                  isDisabled={!!scrapDealer}
                />
                {!isHeadquarter && headquarterError && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            )}

            <BusinessTypeSelector
              handleChange={handleRadioChangeFinal}
              actualValue={true}
            ></BusinessTypeSelector>

            <div className="flex-column">
              <label htmlFor="nationalRegister">
                {t(`${nationalRegisterLabel}`)}*
              </label>
              <InputMask
                mask={nationalRegisterFormat}
                placeholder={nationalRegisterFormat}
                type="text"
                id="nationalRegister"
                {...register("scrapDealerInfo.nationalRegister", {
                  required: true,
                })}
                onChange={(e) => handleInputToAutocompleteForm(e)}
                inputRef={nationalRegisterRef}
              />
              {errors.scrapDealerInfo?.nationalRegister && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="legalName">{`${t("label-legal-name")}*`}</label>
              <input
                placeholder={t("placeholder.legalName")}
                type="text"
                id="legalName"
                {...register("scrapDealerInfo.legalName", { required: true })}
              />
              {errors.scrapDealerInfo?.legalName && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="tradingName">{`${t(
                "label-trading-name"
              )}*`}</label>
              <input
                placeholder={t("placeholder.tradingName")}
                type="text"
                id="tradingName"
                {...register("scrapDealerInfo.tradingName", { required: true })}
              />
              {errors.scrapDealerInfo?.tradingName && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="segment">{t("label-segment")}</label>
              <input
                placeholder={t("placeholder.segment")}
                type="text"
                id="segment"
                {...register("scrapDealerInfo.segment")}
              />
            </div>

            {(scrapDealer?.addressInfo?.countryName === "Brasil" ||
              selectedCountryDetails?.country[0] === "Brasil") && (
              <div className="flex-column">
                <label htmlFor="taxationTypess">{`${t(
                  "label-taxation-type"
                )}`}</label>
                <select
                  id="taxationType"
                  {...register("scrapDealerInfo.taxationType", {
                    required: false,
                  })}
                >
                  <option value=""></option>
                  {Object.entries(taxationTypesENUM).map(([key, value]) => (
                    <option key={key} value={key}>
                      {t(`taxationTypesENUM.${value}`)}
                    </option>
                  ))}
                </select>
                             </div>
            )}
            {(scrapDealer?.addressInfo?.countryName === "Brasil" ||
              selectedCountryDetails?.country[0] === "Brasil") && (
              <div className="flex-column">
                <label htmlFor="mtrCode">{`${t(
                  "label-business-mtr-code"
                )}*`}</label>
                <input
                  placeholder={t("placeholder.mtrCode")}
                  type="text"
                  id="mtrCode"
                  {...register("scrapDealerInfo.mtrCode", { required: true })}
                />
                {errors.scrapDealerInfo?.mtrCode && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            )}
          </div>
          <br />
          <hr />
          <br />
          <div className="form-fields flex-column">
            <h2>{t("label-location")}</h2>
            <div className="flex-column">
              <label htmlFor="postalCode">{t(`${postalCodeLabel}`)}*</label>
              <InputMask
                mask={postalCodeFormat}
                placeholder={postalCodeFormat}
                type="text"
                id="postalCode"
                {...register("addressInfo.postalCode", { required: true })}
                inputRef={postalCodeRef}
              />
              {errors.addressInfo?.postalCode && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="address">{`${t("label-address")}*`}</label>
              <input
                placeholder={t("placeholder.address")}
                type="text"
                id="address"
                {...register("addressInfo.address", { required: true })}
              />
              {errors.addressInfo?.address && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="double-fields">
              <div className="flex-column">
                <label htmlFor="city">{`${t("label-city")}*`}</label>
                <input
                  placeholder={t("placeholder.city")}
                  type="text"
                  id="city"
                  {...register("addressInfo.city", { required: true })}
                />
                {errors.addressInfo?.city && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="state">{`${t("label-state")}*`}</label>
                <input
                  placeholder={t("placeholder.state")}
                  type="text"
                  id="state"
                  {...register("addressInfo.state", { required: true })}
                />
                {errors.addressInfo?.state && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className="form-fields flex-column">
            <h2>{t("label-contact")}</h2>
            <div className="flex-column">
              <label htmlFor="contactName">{`${t(
                "label-contact-name"
              )}`}</label>
              <input
                placeholder={t("placeholder.contactName")}
                type="text"
                id="contactName"
                {...register("contactInfo.name", { required: false })}
              />
             
            </div>

            <div className="double-fields">
              <div className="flex-column">
                <label htmlFor="email">{`${t("label-email")}`}</label>
                <input
                  placeholder={t("placeholder.email")}
                  type="text"
                  id="email"
                  {...register("contactInfo.email", { required: false })}
                />
               
              </div>

              <div className="flex-column">
                <label htmlFor="phone">{`${t("label-phone")}`}</label>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder={t("placeholder.phone")}
                  type="text"
                  id="phone"
                  {...register("contactInfo.phone", { required: false })}
                  inputRef={phoneRef}
                />
               
              </div>

              <div className="flex-column">
                <label htmlFor="function">{`${t("label-function")}`}</label>
                <input
                  placeholder={t("placeholder.generalEmpty")}
                  type="text"
                  id="function"
                  {...register("contactInfo.function", { required: false })}
                />
               
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <br />
          <div className="form-fields flex-column">
            <div className="flex-column">
              <label htmlFor="observations">
                {t("label-general-observations")}
              </label>
              <textarea
                placeholder={t("placeholder.observations")}
                id="observations"
                {...register("observations.observations")}
              />
            </div>
          </div>

          <div className="flex-row buttons-section">
            <button
              className="neutral-white"
              onClick={() => navigate("/scrap-dealers")}
              type="button"
            >
              {t("label-back")}
            </button>
            <button className="blue" type="submit" disabled={isDisabled}>
              {t("label-save")}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default NewScrapDealerForm;
