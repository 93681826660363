import { onIdTokenChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, firestore } from "../utils/firebase-config";
import { COLLECTIONS } from "../assets/enums/firebase-colections";

export function VerifyAccessFunction() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      try {
        if (user) {
          const userRef = doc(firestore, COLLECTIONS.USERS, user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();

            if (userData?.lastAccessDate) {
              const { seconds, nanoseconds } = userData.lastAccessDate;

              const expirationTime = new Date(
                seconds * 1000 + nanoseconds / 1e6
              );
              expirationTime.setMinutes(expirationTime.getMinutes() + 60);

              const updates = {
                lastAccessDate: new Date(),
              };

              if (new Date() > expirationTime) {
                navigate("/logout");
              }

              await updateDoc(userRef, updates);

              const profile = userData.profile || "public";
              localStorage.setItem("scrap4better-session-profile", profile);
            }
          }
        } else {
          handlePublicSession();
        }
      } catch (error) {
        console.error("Erro ao verificar acesso do usuário:", error);
        handlePublicSession();
      }
    });

    function handlePublicSession() {
      localStorage.setItem("scrap4better-session-profile", "public");
      if (
        location.pathname !== "/" &&
        !location.pathname.includes("login") &&
        !location.pathname.includes("privacy-notice")
      ) {
        navigate("/");
      }
    }

    return () => unsubscribe();
  }, [location.pathname, navigate]);

  return null;
}
