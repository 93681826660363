import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ProspectingStatusENUM,
  ScrapProspectingGeneratorsDTO,
} from "../../../scrap-generator-prospecting-DTO";
import { getContrastColor } from "../../../../../services/colors-service";
import { transformDateFormat } from "../../../../../services/date-format";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";

interface Props {
  item: ScrapProspectingGeneratorsDTO;
}

const CardScrapGeneratorProspecting: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stepsRef = useRef<HTMLDivElement>(null);

  const scrollSteps = (
    direction: "left" | "right",
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    if (stepsRef.current) {
      stepsRef.current.scrollBy({
        left: direction === "left" ? -200 : 200,
        behavior: "smooth",
      });
    }
  };

  const getLastStepColorWithDate = () => {
    if (Array.isArray(item.CRMSteps)) {
      const stepsWithDate = item.CRMSteps.filter((step) => {
        const date = step.conclusionDate;

        if (date) {
          const [year, month, day] = date.split("-").map(Number);
          const parsedDate = new Date(year, month - 1, day);
          return !isNaN(parsedDate.getTime());
        }

        return false;
      });

      return stepsWithDate && stepsWithDate.length > 0
        ? stepsWithDate[stepsWithDate.length - 1]?.crmFunnelInfoDTO?.color
        : null;
    }

    return null;
  };

  const lastStepColor = getLastStepColorWithDate();

  return (
    <div
      className="flex-column card-generator"
      style={{
        padding: "20px 20px 10px 20px",
        height: "auto",
        borderLeft: lastStepColor
          ? `10px solid ${lastStepColor}`
          : "10px solid black",
      }}
      onClick={() => {
        navigate(`/scrap-generators/prospecting/${item.id}`);
      }}
    >
      <div
        className="steps-prospection"
        style={{ justifyContent: "space-between" }}
      >
        <div className="flex-row" style={{ gap: 20 }}>
          <div className="flex-column align-center justify-center">
            <SparkIcon
              noPadding
              icName={
                item.status === ProspectingStatusENUM.CHECKED
                  ? "checkmark-frame"
                  : "alert-warning"
              }
              pallete={
                item.status === ProspectingStatusENUM.CHECKED
                  ? "tertiary"
                  : "disabled"
              }
            />
          </div>
          <h3 style={{ margin: 0, alignContent: "center" }}>
            {item.name || "-"}
          </h3>
        </div>
        <div
          className="flex-row"
          style={{
            gap: 10,
            overflowX: "auto",
            scrollBehavior: "smooth",
            scrollbarWidth: "auto",
            margin:"1rem 0rem"
          }}
          ref={stepsRef}
        >
          {item.CRMSteps?.length > 0 ? (
            item.CRMSteps.filter((step) => step?.crmFunnelInfoDTO?.isVisible)
              .sort(
                (a, b) =>
                  (a.crmFunnelInfoDTO?.order || 0) -
                  (b.crmFunnelInfoDTO?.order || 0)
              )
              .map((step, index) => (
                <div
                  key={index}
                  className="flex-column align-center"
                  style={{
                    padding: "10px 20px",
                    backgroundColor: step?.crmFunnelInfoDTO?.color,
                    color: getContrastColor(
                      step?.crmFunnelInfoDTO?.color || "black"
                    ),
                    borderRadius: 10,
                    fontWeight: 600,
                    minWidth: "auto",
                    width: "auto",
                    display: "inline-block",
                    maxWidth: "100%",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  >
                    {step?.crmFunnelInfoDTO?.stageName}
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {step.conclusionDate
                      ? transformDateFormat(step.conclusionDate)
                      : "- -/- -/- - - -"}
                  </p>
                </div>
              ))
          ) : (
            <p>-</p>
          )}
        </div>
      </div>
      <div className="flex-column justify-center align-center">
        <div className="custom-line-hr"></div>
        <p>{item.description || "-"}</p>
      </div>
    </div>
  );
};

export default CardScrapGeneratorProspecting;
