import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import { CollectPeriod } from "../../../../assets/enums/collects-enum";

import ScrapMaterialInputAutocomplete from "../../../../components/ScrapMaterialInputAutocomplete/ScrapMaterialInputAutocomplete";
import { CollectItemDTO } from "../../models/collect-item-DTO";
import { useAlert } from "../../../../contexts/alert-context";
import { addNewScheduled, sendNewCollectRequestEmail } from "../../collects-service";
import { getScrapAdminContract } from "../../../scraps/scrap-admin-service";
import { ScrapInfoDTO } from "../../../scraps/scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../services/address-service";
import { getScrapDealerById } from "../../../scrap-dealers/scrap-dealers-service";
import { useAuth } from "../../../../contexts/auth-context";
import { useNavigate, useParams } from "react-router-dom";
import genericImagePreview from "./../../../../assets/svgs/material-list.svg";
import { CollectStatus } from "../../../../assets/enums/collects-enum";
import CollapsibleSection from "../../../../components/ColapseSection/CollapsibleSection";
import { transformDateFormat } from "../../../../services/date-format";
import { CountryConfigDTO } from "../../../settings/components/Country-config/country-config-DTO";
import { getSuccessFee } from "../../../scrap-generators/scrap-generator-service"; 
import { sendEmailSDM } from "../../../../services/send-email-SDM";
import { generateEmailTemplate } from "../../../../assets/templates/email-template";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { profileENUM } from "../../../../assets/enums/profiles-enum";

interface NewCollectModalScheduledProps {
  originEntity: string;
  item?: CollectItemDTO;
  profile: string;
  onSave: (item: CollectItemDTO) => void;
  onClose: () => void;
}

const NewCollectModalScheduled: React.FC<NewCollectModalScheduledProps> = ({
  item,
  onSave,
  onClose,
  profile,
  originEntity,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CollectItemDTO>({
    defaultValues: item as CollectItemDTO,
  });
  const { userDoc } = useAuth();
  const originId = userDoc?.originId ?? "";
  const { id } = useParams<{ id: string }>();
  const [autocompleteError, setAutocompleteError] = useState(false);
  const [scrapMaterialPreview, setScrapMaterialPreview] = useState<any>();
  const [scrapMaterialContract, setScrapMaterialContract] = useState<any>();
  const [scrapDealerDetails, setScrapDealerDetails] = useState<any>();
  const [countryConfigEntity, setCountryConfigEntity] = useState<CountryConfigDTO>();
  const [intermediationFee, setIntermediationFee] = useState<number | null>(null);
  const [showIntermediationFeeAlert, setShowIntermediationFeeAlert] = useState(false);
  const navigate = useNavigate();
  const language: string = countryConfigEntity?.locale.split("-")[0] === "pt" ? "pt" : "en";
  const { setAlert } = useAlert();
  const [submitType, setSubmitType] = useState<string | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [emailSendChecked, setEmailSendChecked] = useState(true); 
  const [emailSendClientChecked, setEmailSendClientChecked] = useState(false);  



  const fetchEntityCountry = async () => {
    try {
      const entityId = id || originId;
      if (entityId) {
        const data = await getAdrressByEntityId(entityId);
        if (data?.country) {
          const countryConfig = await getCountryConfigById(data.country);
          setCountryConfigEntity(countryConfig);
        }
      }
    } catch (error) {
      console.error("Failed to fetch country configuration:", error);
    }
  };

  useEffect(() => {
    fetchEntityCountry();
  }, []);


  const fetchIntermediationFee = async (generatorId: string | undefined) => {
    if (!generatorId) return;
    try {
      const successFee = await getSuccessFee(generatorId);
      setIntermediationFee(successFee?.value || null);


      setShowIntermediationFeeAlert(!successFee?.value);
    } catch (error) {
      console.error("Erro ao buscar taxa de intermediação:", error);
    }
  };
 const handleSendEmail = async () => {
  
    const subject = t("Complete Fee Intermediation");
    const generatorName = item?.scrapGeneratorName || "";
    const emailContent = `
      <div>
        <h2>${t("Complete Fee Intermediation")}</h2>
        <ul>
          <li><strong>${t("label-scrap-generator")}:</strong> ${generatorName}</li>
         
        </ul>
        <p>${t("label-Access-the-platform-and-complete-the-Intermediation-Fee-to-enable-Waste handling")}</p>
        
      </div>
    `;

    

    try {
      await sendEmailSDM(subject, emailContent);
      setAlert({
        text: t("label-Email-sent-successfully"),
        type: "success",
      });
    } catch (error) {
      
      setAlert({
        text: t("alert-failed-email"),
        type: "error",
      });
    }
  };


  

  useEffect(() => {
    if (item?.generatorId) {
      fetchIntermediationFee(item.generatorId);
    }

  }, [item?.generatorId]);

  useEffect(() => {
    if (item?.generatorId) {
      fetchIntermediationFee(item.generatorId);
    }
  }, []);

  useEffect(() => {
    if (id || originId) {
      fetchIntermediationFee((id || originId) as string);
    }
  }, []);

  const handleSetScrapMaterial = async (scrapMaterial: ScrapInfoDTO) => {
    setValue("scrapMaterial", scrapMaterial);
    setScrapMaterialPreview(scrapMaterial);
    setAutocompleteError(false);

    const contractDetails = await getScrapAdminContract(scrapMaterial.id);
    setScrapMaterialContract(contractDetails);

    const scrapDealerDetails = await getScrapDealerById(
      scrapMaterial.contractDealer
    );
    setScrapDealerDetails(scrapDealerDetails);


  };

  const getImageSrc = (scrapMaterialPreview: any) => {
    if (
      scrapMaterialPreview?.images &&
      scrapMaterialPreview.images.length > 0
    ) {
      return scrapMaterialPreview.images[scrapMaterialPreview.images.length-1];
    }  else {
      return genericImagePreview;
    }
  };

  const onSubmit = async (data: CollectItemDTO) => {
    setIsDisabled(true);
    if (!data.scrapMaterial) {
      setAutocompleteError(true);
    } else {
      data.unity = scrapMaterialPreview.unity;
      data.valueByUnity = scrapMaterialContract?.contractValue;
      data.generatorId = data.scrapMaterial.originId;
      data.contractedDealerId = data.scrapMaterial.contractDealer;
      data.scrapMaterialName = data.scrapMaterial.scrapCategory.name;
      data.scrapGeneratorName = data.scrapMaterial.originName;
      data.isHazardousMaterial = data.scrapMaterial.scrapCategory.isHazardousMaterial;
      data.scrapDealerName = data.scrapMaterial.contractDealerName;
      data.scrapMaterial = data.scrapMaterial.id;
      data.status = CollectStatus.SCHEDULED;
      if (countryConfigEntity) {
        data.currency = countryConfigEntity.currency;
        data.locale = countryConfigEntity.locale;
      }
      if (data.observations === undefined) {
        data.observations = "";
      }
  
      await addNewScheduled(data, language).then(async () => {
        if (emailSendChecked) {
          await sendNewCollectRequestEmail(data, language, false);  
        }
  
        if (emailSendClientChecked) {
          await sendNewCollectRequestEmail(data, language, true);  
        }
  
        setAlert({
          text: t("alert-successfully-registered"),
          type: "success",
        });
  
        onClose();
        onSave(data);
      });
  
      setIsDisabled(false);
    }
  };
  

  return (
    <div
      className="big-modal-content"
      style={{ gap: "20px", padding: "1rem 1rem 2rem 1rem" }}
    >
      <div onClick={onClose} style={{display:"flex",justifyContent:"flex-end"}}>
          <SparkIcon
            
            className="icon-flag"
            no-padding={true}
            icName={"close"}
          />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields">
          <CollapsibleSection title={t("label-collect")} titleFontSize="18px">

            {showIntermediationFeeAlert && (
              <div
                style={{
                  backgroundColor: "#00539f",
                  color: "white",
                  padding: "8px",
                  borderRadius: "4px",
                  marginBottom: "10px",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                {profile === profileENUM.ADMIN_BOSCH ? (
                  <>
                    {t("label-Necessary-to-complete-the-intermediation-fee")}
                    <span
                      onClick={() => {
                        navigate(`/scrap-generators-details/${item?.generatorId || id || originId}/fee`);

                      }}
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      {t("label-fee")}
                    </span>
                  </>
                ) : (
                  <>
                    {t("label-If-you-need to complete your registration, please contact")}
                    <span
                      onClick={handleSendEmail}
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      {t("label-Contact-our-team")}
                    </span>
                  </>
                )}
              </div>
            )}
            <div className="flex-column">
              <ScrapMaterialInputAutocomplete
                originEntity={originEntity}
                initialValue={item?.scrapMaterial}
                isDisabled={item ? true : false}
                label={`${t("label-material")}*`}
                onChange={(scrapMaterial) =>
                  handleSetScrapMaterial(scrapMaterial)
                }
                placeholder={t("label-material")}
              />
              <div className="flex-row justify-between">
                {autocompleteError && <span>{t("error-input-required")}</span>}
                <div></div>
                <p
                  style={{
                    color: "var(--extra-grey-medium)",
                    fontSize: 12,
                  }}
                >
                  {t("label-material-contracted")}
                </p>
              </div>
            </div>

            {scrapMaterialPreview && (
              <div
                className="resume-material-collect flex-row margin-topbottom-1"
                style={{ gap: 10 }}
              >
                <div className="flex-column">
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: 5,
                    }}
                    src={getImageSrc(scrapMaterialPreview)}
                  />
                </div>
                <div
                  className="flex-column justify-between"
                  style={{ width: "100%" }}
                >
                  <div className="flex-column" style={{ gap: 5 }}>
                    <div className="justify-between flex-row">
                      <h3 style={{ margin: 0, marginBottom: 8 }}>
                        {scrapMaterialPreview.scrapCategory.name}
                      </h3>
                      <a
                        href={
                          profile === profileENUM.ADMIN_BOSCH
                            ? `/scrap-generators-details/${scrapMaterialPreview.originId}/scraps-form/${scrapMaterialPreview.id}`
                            : `/generator-scraps/${scrapMaterialPreview.originId}/generator-scraps-form/${scrapMaterialPreview.id}`
                        }
                        className="a-link-style"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("label-details")}
                      </a>
                    </div>
                  </div>
                  <div className="flex-column" style={{ gap: 2 }}>
                    <h5 style={{ margin: 0 }}>
                      {t("material-contract-date")}:
                    </h5>
                    <p>{`${transformDateFormat(
                      scrapMaterialContract?.contractDate
                    )}  ${t("label-to").toLowerCase()}  ${transformDateFormat(
                      scrapMaterialContract?.expirationDocument.expirationDate
                    )}`}</p>
                  </div>
                  <div className="flex-row justify-between align-end">
                    <div className="flex-column" style={{ gap: 2 }}>
                      <h5 style={{ margin: 0 }}>
                        {t("label-responsible-shipping")}:
                      </h5>
                      <p>
                        {t(`label-${scrapMaterialPreview.responsibleShipping}`)}
                      </p>
                    </div>
                    <div className="flex-column align-end">
                      <div className="flex-row" style={{ gap: 5 }}>
                        <h3 style={{ margin: 0 }}>
                          {scrapMaterialContract?.contractValue || "xx"}
                        </h3>
                        <p>{`/${t(
                          `unityENUM.${scrapMaterialPreview.unity}`
                        )}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <br />

            <div className="flex-column">
              <label>{`${t("label-date")}*`}</label>
              <input
                type="date"
                id="date"
                {...register("date", {
                  required: true,
                })}
              />
            </div>

            <br />


            <div className="flex-column">
              <label>{t("label-period")}</label>
              <select {...register("period")}>
                {Object.values(CollectPeriod).map((period) => (
                  <option key={period} value={period}>
                    {t(`collect-periodENUM.${period}`)}
                  </option>
                ))}
              </select>
            </div>

            <br/>

            <div className="flex-row align-center">
            <label style={{ gap: 5 }}>
              <input
                type="checkbox"
                checked={emailSendChecked}   
                onChange={() => setEmailSendChecked((prev) => !prev)} 
              />
              <h4 style={{ margin: 0 }}>
                {t("label-email-send")}
              </h4>
            </label>
          </div>

            <br/>

            <div className="flex-row align-center">
            <label style={{ gap: 5 }}>
              <input
                type="checkbox"
                checked={emailSendClientChecked}  
                onChange={() => setEmailSendClientChecked((prev) => !prev)}  
              />
              <h4 style={{ margin: 0 }}>
                {t("label-email-send-client")}
              </h4>
            </label>
          </div>

            <br/>
            <hr></hr>
            <br />
            <div className="flex-column">
              <label htmlFor="label-observations">
                {t("label-observations")}
              </label>
              <input
                disabled={!scrapMaterialPreview}
                placeholder={t("label-observations")}
                type="text"
                id="label-observations"
                {...register("observations", {
                  required: false,
                })}
              />
            </div>
          </CollapsibleSection>

          
          <div
            className="flex-row justify-end"
            style={{ gap: "10px", marginTop: "1.5rem" }}
          >
          
            <button onClick={onClose} type="button" style={{ margin: 0 }}>
              {t("label-cancel")}
            </button>
            <button  
            className="blue" 
            type="submit" 
            style={{ margin: 0, width:"35%" }}
            disabled={isDisabled}
            >
              {t("label-save-scheduled")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewCollectModalScheduled;
