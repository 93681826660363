import React, { useState, useEffect } from "react";
import UploadModal from "./components/UploadFileOrImagesModal";
import { t } from "i18next";
import { extractFileNameFromUrl } from "../../services/string-treatment";

interface UploadFileOrImageProps {
  onlyImages: boolean;
  onSave: (files: File[], removedFiles: string[]) => void;
  initialSavedFiles?: string[];
  fieldLabel?: string;
}

const UploadFileOrImage: React.FC<UploadFileOrImageProps> = ({
  onlyImages,
  onSave,
  initialSavedFiles = [],
  fieldLabel,
}) => {
  const normalizeFiles = (files: unknown): string[] => {
    if (
      Array.isArray(files) &&
      files.every((file) => typeof file === "string")
    ) {
      return files;
    }
    return [];
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [savedFiles, setSavedFiles] = useState<string[]>(() =>
    normalizeFiles(initialSavedFiles)
  );
  const [removedSavedFiles, setRemovedSavedFiles] = useState<string[]>([]);

  useEffect(() => {
    const normalizedFiles = normalizeFiles(initialSavedFiles);
    if (JSON.stringify(savedFiles) !== JSON.stringify(normalizedFiles)) {
      setSavedFiles(normalizedFiles);
    }
  }, [initialSavedFiles]);

  const handleModalSave = (files: File[], removedFiles: string[]) => {
    setSelectedFiles(files);
    setRemovedSavedFiles(removedFiles);

    const updatedSavedFiles = savedFiles.filter(
      (file) => !removedFiles.includes(file)
    );
    setSavedFiles(updatedSavedFiles);

    onSave(files, removedFiles);
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleFileClick = (file: File | string) => {
    const fileURL = typeof file === "string" ? file : URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  return (
    <div className="margin-topbottom-1">
      <h4 style={{ margin: "10px 0px 10px 0px" }}>{fieldLabel}</h4>

      <button
        style={{ margin: "10px 0px 10px 0px" }}
        type="button"
        onClick={() => setIsModalOpen(true)}
      >
        {t("label-select-files")}
      </button>

      {isModalOpen && (
        <UploadModal
          onlyImages={onlyImages}
          initialFiles={selectedFiles}
          savedFiles={savedFiles}
          onClose={handleModalClose}
          onSave={handleModalSave}
          fieldLabel={fieldLabel}
        />
      )}

      {(selectedFiles.length > 0 || savedFiles.length > 0) && (
        <div className="margin-topbottom-1">
          <h4 style={{ margin: 0 }}>{t("label-selected-files")}:</h4>
          <div style={{ marginTop: "10px" }}>
            {onlyImages ? (
              <div className="image-preview flex-row">
                {savedFiles.map((file, index) => (
                  <div
                    key={`saved-${index}`}
                    style={{
                      marginBottom: "10px",
                      cursor: "pointer",
                      backgroundColor: "var(--box-shadow-color)",
                    }}
                    onClick={() => handleFileClick(file)}
                  >
                    <img
                      src={file}
                      alt={`saved-preview-${index}`}
                      className="image"
                    />
                  </div>
                ))}
                {selectedFiles.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: "10px",
                      cursor: "pointer",
                      backgroundColor: "var(--box-shadow-color)",
                    }}
                    onClick={() => handleFileClick(file)}
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`preview-${index}`}
                      className="image"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <ul className="flex-column">
                {savedFiles.map((file, index) => (
                  <li
                    className="flex-row align-center justify-between preview-file-line"
                    style={{ padding: 10, cursor: "pointer" }}
                    key={`saved-${index}`}
                    onClick={() => handleFileClick(file)}
                  >
                    <p style={{ margin: "10px" }}>
                      {extractFileNameFromUrl(file)}
                    </p>
                  </li>
                ))}
                {selectedFiles.map((file, index) => (
                  <li
                    className="flex-row align-center justify-between"
                    style={{ padding: 10, cursor: "pointer" }}
                    key={index}
                    onClick={() => handleFileClick(file)}
                  >
                    <p style={{ margin: "10px" }}>{file.name}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadFileOrImage;
