import React, { useEffect, useState } from "react";
import Select from "react-select";
import fetchCountries from "./CountryInputAutocompleteService";
import { useTranslation } from "react-i18next";

const CountryInputAutocomplete: React.FC<{
  label: string;
  placeholder: string;
  register: any;
  errors: any;
  onChange: (country: any) => void;
  initialValue?: any;
  isDisabled?: boolean;
}> = ({
  label,
  placeholder,
  register,
  errors,
  onChange,
  initialValue,
  isDisabled,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<any>(
    initialValue || null
  );
  const [countryOptions, setCountryOptions] = useState<any[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countries = await fetchCountries();
        const options = countries.map((country) => ({
          value: country.id,
          label: country.country[0],
          data: country,
        }));
        setCountryOptions(options);

        if (initialValue) {
          const initialCountry = options.find(
            (option) => option.label === initialValue
          );
          if (initialCountry) {
            setSelectedCountry(initialCountry.data);
            onChange(initialCountry.data);
          }
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchData();
  }, [initialValue]);

  const handleChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.data) {
      setSelectedCountry(selectedOption.data);
      onChange(selectedOption.data);
    } else {
      setSelectedCountry(null);
      onChange(null);
    }
  };

  return (
    <div className="flex-column select-field" style={{ gap: 10 }}>
      <label htmlFor="country">{label}</label>
      <Select
        id="country"
        placeholder={placeholder}
        value={
          selectedCountry
            ? { value: selectedCountry.id, label: selectedCountry.country[0] }
            : null
        }
        onChange={handleChange}
        options={countryOptions}
        isDisabled={isDisabled}
      />
      {errors.country && <span>{t("error-input-required")}</span>}
    </div>
  );
};

export default CountryInputAutocomplete;
